.contact-us-container {
  width: 40%;
  margin: 0 auto;
}

.contact-us-textarea {
  font-size: 16px;
  /* padding: 0.5rem; */
}

@media only screen and (max-width: 1000px) {
  .contact-us-container {
    width: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .contact-us-container {
    width: 100%;
    padding: 1em;
    background: linear-gradient(180deg, #1675aa 225px, #e5e7eb 0, #e5e7eb 100%);
    min-height: 1080px;
  }
}

.app-login-container {
  width: 40%;
  margin: auto;
  padding-top: 10rem;
}

.truckdesk-logo{
  width: 60%;
}

@media only screen and (max-width: 1000px) {
  .app-login-container {
    width: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .app-login-container {
    width: 85%;
    margin-top: 2rem;
    padding-top: 2rem;
  }
}

.login-button {
  margin-top: 1rem;
}

.button {
  background-color: #03a9f4;
  color: white;
  letter-spacing: 1px;
  outline: none;
  border: none;
  padding: 20px 80px;
  font-size: 16px;
  border-radius: 5px;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 600px) {
  .button {
    font-size: 20px;
    padding: 20px 55px;
  }
}

.button:hover {
  background-color: #0298db;
  color: white;
  cursor: pointer;
}

:root {
  --main-font: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Ubuntu, Arial, sans-serif;
}

.selected-label {
  font-family: var(--main-font);
  font-size: 18px;
}

.selected-container {
  margin-bottom: 1rem;
}

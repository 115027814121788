.truckdesk-logo {
  width: 20rem;
  margin: 0rem auto;
  display: block;
  margin-bottom: 2rem;
}

.app-register-container {
  width: 40%;
  margin: auto;
  padding-top: 2rem;
}

@media only screen and (max-width: 1000px) {
  .app-register-container {
    width: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .app-register-container {
    width: 90%;
  }
}

#register-input-email,
#register-input-password {
  width: 100%;
  margin-bottom: 1rem;
}

.register-container {
  width: 50%;
  margin: 0 auto;
  margin-top: 8rem;
}

#forgot-password-email-input {
  width: 100%;
}

.forgot-password-container {
  width: 60%;
  margin: 2rem auto;
}

@media only screen and (max-width: 1000px) {
  .forgot-password-container {
    width: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .forgot-password-container {
    width: 90%;
  }
}

.login-button {
  margin-top: 1rem;
}

.select-doc-button {
  border: 2px #1675aa solid;
  background: #fff;
  color: #1675aa;
  margin-top: 0.6rem;
  border-radius: 3rem;
  cursor: pointer;
  padding:0.25rem 1rem;
  margin-right: 0.25rem;
  font-size: 13px;
}

.select-doc-button-selected {
  border: none;
  background: #1675aa;
  color: white;
  margin-top: 0.6rem;
  border-radius: 3rem;
  padding:0.25rem 1rem;
  margin-right: 0.25rem;
  font-size: 14px;
}

body {
  margin: 0;
}

:root {
  --bg: #fff;
  --bg-accent: #f1f1f1;
  --text-color: black;
  --nav-size: 60px;
  --border: 1px solid #ccc;
  --border-radius: 8px;
  --speed: 500ms;
  --main-font: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Ubuntu, Arial, sans-serif;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: var(--text-color);
  text-decoration: none;
  font-family: var(--main-font);
}

/* Top Navigation Bar */

/* <nav> */
.navbar {
  height: var(--nav-size);
  background-color: var(--bg);
  padding: 0 1rem;
  border-bottom: var(--border);
}

/* <ul> */
.navbarNav {
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

/* <li> */
.navItem {
  width: calc(var(--nav-size) * 1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.upgradeButton {
  height: 40px;
  width: 5rem;
  margin-top: 10px;
  margin-right: 10px;
  background-color: transparent;
  border-radius: 5px;
  color: #03a9f4;
  border: 1px solid #03a9f4;
  cursor: pointer;
  font-family: var(--main-font);
}

.upgradeButton:hover {
  color: #0298db;
  border: 1px solid #0298db;
}

/* Icon Button */
.iconButton {
  --button-size: calc(var(--nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  background-color: #dddddd;
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
}

.iconButton:hover {
  filter: brightness(1.2);
}

/* Dropdown Menu */

.dropdown {
  position: absolute;
  top: 58px;
  width: 200px;
  transform: translateX(-40%);
  background-color: var(--bg);
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 1rem 1rem 1rem 0;
  overflow: hidden;
  transition: height var(--speed) ease;
  z-index: 2;
}

.menu {
  width: 100%;
}

.menuItem {
  height: 40px;
  display: flex;
  align-items: center;
  /* border-radius: var(--border-radius); */
  border-radius: 0;
  transition: background var(--speed);
  padding: 0.5rem;
  width: 100%;
}

.menuItem:hover {
  background-color: #dddddd;
  cursor: pointer;
}

.truckdeskNavbarLogo {
  float: left !important;
  position: relative;
  width: 10rem;
  margin-top: 5px;
}

/* ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 25%;
  background-color: #f1f1f1;
  position: fixed;
  height: 100%;
  overflow: auto;
} */

:root {
  --main-font: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Ubuntu, Arial, sans-serif;
}

.side-navbar {
  float: left;
  position: fixed;
  top: 0;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
  width: 180px;
  z-index: 100;
  height: 100vh;
  padding-top: 50px;
  /* transition: width 0.4s linear; */
}

.side-navbar-title {
  margin-left: 10px;
  font-family: var(--main-font);
}

.side-navbar-icon,
.side-navbar-title {
  display: inline-block;
  vertical-align: top;
  text-align: center;
}

@media screen and (max-width: 1200px) {
  .side-navbar-title {
    display: none;
  }

  .side-navbar {
    width: 60px;
    transition: 0.3s;
  }

  .side-navbar-icon {
    margin-left: 5px;
  }
}

@media screen and (max-width: 592px) {
  .side-navbar {
    display: none;
  }
}

/* Style the buttons inside the tab */
.side-navbar li {
  display: block;
  background-color: inherit;
  color: black;
  padding: 20px 16px;
  /* width: 100%; */
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.side-navbar li:hover {
  background-color: #ddd;
}

/* Create an active/current "tab button" class */
.side-navbar button.active {
  background-color: #ccc;
}

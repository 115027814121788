.profile-container {
  width: 100%;
  padding-top: 2rem;
}

#profile-app-textinput {
  width: 100%;
}

#account-number,
#account-name {
  width: 100%;
}


#profile-app-container{
  padding: 1em;
  background: linear-gradient(180deg, #1675aa 175px, #e5e7eb 0, #e5e7eb 100%);
  width:100%;
}


.equip_dd .css-g1d714-ValueContainer {
  padding: 8px 10px;
}
#top-navbar {
  overflow: hidden;
  background-color: #fff;
  position: fixed;
  top: 0;
  height: 60px;
  width: 100%;
}

#top-navbar span {
  float: right;
  display: block;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

#top-navbar span:hover {
  background: #ddd;
  color: black;
}

.top-nav-user-button {
  background-color: transparent;
  width: 5rem;
  height: 2rem;
  border-radius: 5px;
  border: 1px solid #dddddd;
}

.top-nav-user-button:hover {
  /* background-color: #dddddd; */
  cursor: pointer;
}

.notification-icon,
.hamburger-icon {
  margin-top: 4px;
}

#top-navbar .hamburger-icon {
  float: left;
  margin-left: 5px;
}

/* mobile navbar for small screens */

.nav-menu {
  width: 180px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: black;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
}

.nav-text a:hover {
  background-color: #ddd;
}

.nav-menu-items {
  width: 180px;
}

.navbar-toggle {
  margin-left: 1rem;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

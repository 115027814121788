.uploaded-image-preview {
  width: 10rem;
}

.driver-app-upload-container {
  width: 60%;
  margin: 0rem auto;
  padding-top: 2rem;
  padding: 2rem 1rem;
  background: linear-gradient(180deg, #1675aa 175px, #e5e7eb 0, #e5e7eb 100%);
  height: 100%;
}

.textarea-add-message {
  padding: 0.5rem;
  font-size: 16px;
  box-sizing: border-box;
}

@media only screen and (max-width: 1000px) {
  .driver-app-upload-container {
    width: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .driver-app-upload-container {
    width: 100%;

  }

  .uploaded-image-preview {
    width: 10rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-right: 0.3rem;
  }
}

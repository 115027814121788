.upload-image-container {
  /* display: flex;
  align-items: center;
  justify-content: flex-end; */
}

.image-upload-button {
  background-color: #03a9f4;
  color: white;
  letter-spacing: 1px;
  outline: none;
  border: none;
  font-size: 16px;
  border-radius: 5px;
  text-transform: uppercase;
  cursor: pointer;
  width: 100%;
  display: inline-block;
}

.image-upload-button:hover {
  background-color: #0298db;
}

@media only screen and (max-width: 600px) {
  .image-upload-button {
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .image-upload-button {
    font-size: 18px;
  }
}

:root {
  --main-font: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Ubuntu, Arial, sans-serif;
}

body {
  font-family: var(--main-font);
}

.label {
  font-size: 16px;
  color: black;
  display: 'block';
  font-weight: 600;
}

.sublabel {
  font-size: 16px;
  color: grey;
  margin: 0;
  padding: 0;
}

.text-input-container {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: var(--main-font);
}

.inputElement {
  width: 40%;
  padding: 8px 10px;
  margin: 0.5rem 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  background-color: #ffffff;
  font-family: var(--main-font);
  height: 2.0rem;
}

.inputContainer {
  width: 100%;
  margin-bottom: 3px;
}

.Field {
  width: 100%;
  padding: 10px 0px 10px 0px;
  font-size: 20px;
  padding-left: 35px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@media only screen and (max-width: 600px) {
  input {
    width: 80% !important;
  }
}

.driver-app-document-container {
  width: 60%;
  margin: 0rem auto;
  height: 100%;
  background: linear-gradient(180deg, #1675aa 175px, #e5e7eb 0, #e5e7eb 100%);
  padding: 0.5rem 1rem;

}

@media only screen and (max-width: 1000px) {
  .driver-app-document-container {
    width: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .driver-app-document-container {
    width: 100%;
    /* text-align: center; */
  }
}

#company-name-input {
  width: 100%;
}

.driver-app-container-add-image {
  text-align: center;
  margin-top: 3rem;
}
